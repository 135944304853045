import { render, staticRenderFns } from "./BankAccountManager.vue?vue&type=template&id=4df7a28c&scoped=true&"
import script from "./BankAccountManager.vue?vue&type=script&lang=js&"
export * from "./BankAccountManager.vue?vue&type=script&lang=js&"
import style0 from "./BankAccountManager.vue?vue&type=style&index=0&id=4df7a28c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df7a28c",
  null
  
)

export default component.exports