export default class FormatsHandler {
    constructor() {

    }

    getStatusDotClass(status) {
        const availableStatuses = ['available', 'pending', 'failed', 'failed_card_payment', 'failed_bank_payment'];

        if (availableStatuses.indexOf(status.toString()) > -1) {
            return `${status}-dot`;
        }

        return '';
    }

    formatCurrency(amount) {
        if (parseFloat(amount) && parseFloat(amount) > 0) {
            return `+ kr. ${this.formatAmount(amount)}`;
        }

        if (parseFloat(amount) && parseFloat(amount) < 0) {
            amount = parseFloat(amount) * -1;

            return `- kr. ${this.formatAmount(amount)}`;
        }

        return this.formatAmount(0);
    }

    formatAmount(amount) {
        return new Number(parseFloat(amount).toFixed(2)).toLocaleString('da-DK', {minimumFractionDigits: 2});
    }
}
