import Axios from "@/models/axios";
import AxiosConfig from '@/configs/axiosConfig';

class LinkBankAccountApi extends Axios {
  constructor(formData, config) {
    super();
    this.formData = formData;
    this.config   = config;
    this.baseUrl  =  AxiosConfig.BASE_API_URL;
  }

  path() {
    return 'bank-accounts';
  }
}

export default LinkBankAccountApi;
