import { render, staticRenderFns } from "./ModalRemoveCard.vue?vue&type=template&id=87b3236a&scoped=true&"
import script from "./ModalRemoveCard.vue?vue&type=script&lang=js&"
export * from "./ModalRemoveCard.vue?vue&type=script&lang=js&"
import style0 from "./ModalRemoveCard.vue?vue&type=style&index=0&id=87b3236a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b3236a",
  null
  
)

export default component.exports