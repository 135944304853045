<template>
  <div>
  <div class="cards-manager">

      <div class="manage-cards-sections" ref="showBankAccountForm" >
        <div class="manage-cards-section manage-header">
          <h3>
            {{ $translations.getTranslation('bank_account_manager_table_title1') }} <b-icon-info-circle-fill class="more-info-icon" variant="info" @click="showLinkingInfo()"/>
          </h3>

          <div v-if="wallet.cards.length === 0 && !showAddBankAccountForm">
            <a @click.prevent="addBankAccount()" class="link-reverse-standard" role="link">
              <p>
                {{ $translations.getTranslation('bank_account_manager_table_add_bank_account_button') }}
              </p>

              <span>
                <b-icon icon="plus" aria-hidden="true" role="presentation"></b-icon>
              </span>
            </a>
          </div>
        </div>
      </div>

      <link-bank-account @onFormSubmitted="hideFormAndReloadBankAccounts" v-if="showAddBankAccountForm"/>

      <b-table v-if="!showAddBankAccountForm" class="cards-table" striped hover :fields="cardsTableFields" :items="wallet.cards" :per-page="wallet.visibleLimit" show-empty>
        <template slot="empty">
          <i v-if="isLoading()">{{ $translations.getTranslation('bank_account_manager_loading_data_label') }}</i>
          <i v-else>{{ $translations.getTranslation('bank_account_manager_table_no_bank_account_message_label') }}</i>
        </template>

        <template v-slot:cell(type)>
            <span class="scheme-logo-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-bank ml-2" viewBox="0 0 16 16">
  <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
</svg>
            </span>
        </template>

        <template v-slot:cell(account_number_last_3)="data">
          <div class="wrapper">
            <span class="card-number">
              *** {{ data.item.account_number_last_3 }}
            </span>
          </div>
        </template>

        <template v-slot:cell(linked_at)="data">
          <div class="wrapper">
            <span class="linked-at">
              Tilføjet d. {{ data.item.linked_at }}
            </span>
          </div>
        </template>

        <template v-slot:cell(public_id)="data">
          <div class="wrapper">
            <a href="#" target="_self" v-on:click.prevent="removeBankAccountModalTrigger(data)" role="button">
              <p>
                {{ $translations.getTranslation('bank_account_manager_table_remove_bank_account_button') }}
              </p>

              <span>
                <b-icon icon="dash" aria-hidden="true" role="presentation"></b-icon>
              </span>
            </a>
          </div>
        </template>

      </b-table>

    <div class="manage-more" v-if="isViewAllCardsBtnVisible()">
      <a href="#" target="_self" class="text-right" v-on:click.prevent="viewAllCardsOnClick()" role="button">
        {{ $translations.getTranslation('bank_account_manager_table_view_all_bank_accounts_button') }} <b-icon icon="chevron-down" aria-hidden="true" role="presentation"></b-icon>
       </a>
    </div>

    <modal-remove-bank-account :data="removeInfo" @onBankAccountRemove="onBankAccountRemove"/>
    <modal-bank-account-removed @onAddAnotherBankAccount="addBankAccount"/>
    <modal-last-card-removed/>
    <modal-max-cards-reached/>
    <modal-show-linking-info/>
  </div>
  </div>
</template>

<script>
  // Mixins
  import { mixinRoutesHandler } from '@/mixins/routesHandler';
  import { mixinWindowResizeListener } from '@/mixins/windowResizeListener';

  // Modals
  import LinkBankAccount from '@/views/linkBankAccount/LinkBankAccount';
  import ModalRemoveBankAccount from '@/views/manageCards/components/ModalRemoveBankAccount';
  import ModalBankAccountRemoved from '@/views/manageCards/components/ModalBankAccountRemoved';
  import ModalLastCardRemoved from '@/views/manageCards/components/ModalLastCardRemoved';
  import ModalMaxCardsReached from '@/views/manageCards/components/ModalMaxCardsReached';

  // Models
  import BankAccountsApi from "@/models/bankAccountsApi";

  // Icons
  import {BIcon, BIconInfoCircleFill} from 'bootstrap-vue';
  import ModalShowLinkingInfo from "@/views/manageCards/components/ModalShowLinkingInfo";

  export default {
    name: 'BankAccountManager',

    mixins: [mixinWindowResizeListener, mixinRoutesHandler],

    components: {
      ModalShowLinkingInfo, LinkBankAccount, ModalRemoveBankAccount, ModalBankAccountRemoved, ModalLastCardRemoved, ModalMaxCardsReached, BIcon, BIconInfoCircleFill
    },
    props: {
      showForm: {
        default: false,
        type: Boolean
      }
    },
    data: function() {
      return {
        showAddBankAccountForm: false,
        cardsTableFields: [

          {
            key: 'type',
            sortable: false
          },
          {
            key: 'account_number_last_3',
            sortable: false
          },
          {
            key: 'linked_at',
            sortable: false
          },
          {
            key: 'public_id',
            sortable: false
          }
        ],

        wallet: {
          total: null,
          visibleLimit: 2,
          cards: []
        },

        removeInfo: {
          number: null,
          publicId: null,
        },

        desktopBreakPoint: 980,

        loading: false,
      }
    },

    methods: {
      startLoader() {
        this.loading = true;
      },

      stopLoader() {
        this.loading = false;
      },

      isLoading() {
        return this.loading;
      },

      isMaxNumberOfCardsReached() {
        return this.wallet.cards.length < 15;
      },

      addBankAccount() {
        this.showAddBankAccountForm = true;

      },

      goToRoute(routeName) {
        this._mixinGoToRouteName(routeName);
      },

      getRoutePath(routeName) {
        return this._mixinGetRoutePath(routeName);
      },

      removeBankAccountModalTrigger(data) {
        this.removeInfo.number   = data.item.account_number_last_3;
        this.removeInfo.publicId = data.item.public_id;

        this.$root.$emit('bv::show::modal', 'modal-remove-bank-account');
      },

      prepareCardsListForDesktopView(windowWith) {
        if (windowWith >= this.desktopBreakPoint && this.wallet.total !== this.wallet.visibleLimit) {
          this.showAllCards();
        }
      },

      isViewAllCardsBtnVisible() {
        return this.wallet.total > this.wallet.visibleLimit;
      },

      showAllCards() {
        this.wallet.visibleLimit = this.wallet.total;
      },

      viewAllCardsOnClick() {
        this.showAllCards();
      },

      getBankAccounts() {
        this.startLoader();

        let _this = this;
        new BankAccountsApi({}, {}).get(function (response) {
          _this.stopLoader();

          if (!response.status || response.status === 401 || response.status === 403) {
            _this.$root.killAuth();
            return;
          }

          if (response.status !== 200) {
            // No need for global error, the Bank accounts table will show a message "No transactions to show."
            return;
          }

          _this.wallet.cards = response.data;
          _this.updateCardsLength();
        });
      },

      getTotalCards() {
        return this.wallet.total;
      },

      updateCardsLength() {
        this.wallet.total = this.wallet.cards.length;
      },

      hideFormAndReloadBankAccounts() {
        this.showAddBankAccountForm = false;
        this.getBankAccounts();
      },

      onBankAccountRemove(data) {
        let _this = this;
        new BankAccountsApi({}, {urlExtension: data.publicId}).delete(function (response) {
          if (!response.status || response.status === 401 || response.status === 403) {
            _this.$root.killAuth();
            return;
          }

          if (response.status !== 200) {
            _this.$root.$emit('bv::hide::modal', 'modal-remove-bank-account');

            _this.$root.$emit('ls::global-alert::error', _this.$translations.getTranslation(
                'bank_account_manager_remove_bank_account_failed_error_message'
            ));

            return;
          }

          _this.$root.$emit('bv::hide::modal', 'modal-remove-bank-account');

          _this.getBankAccounts();

          _this.$root.$emit('bv::show::modal', 'modal-bank-account-removed');
        });
      },
      showLinkingInfo() {
        this.$root.$emit('bv::show::modal', 'modal-show-linking-info');
      }
    },

    mounted: function () {
      this.$nextTick(function () {
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
          if (modalId === 'modal-pure-dankort-card-linked') {
            this.addBankAccount();
            const el = this.$refs.showBankAccountForm;
            if (typeof el !== "undefined") {
              el.scrollIntoView(false);
            }

          }
        });
        this.getBankAccounts();
      });
      if (this.showForm) {
        this.addBankAccount();
      }
    },

    watch: {
      'mixinWindowResizeListenerData.width': function (newVal) {
        this.prepareCardsListForDesktopView(newVal);
      },

      'wallet.cards': function () {
        this.prepareCardsListForDesktopView(this.mixinWindowResizeListenerData.width);
      },
    },
  }
</script>

<style scoped lang="scss">
  div {
    ::v-deep .cards-manager {
      .link-reverse-standard:hover{
        cursor: pointer;
      }
      .more-info-icon:hover {
          cursor: pointer;
      }

      width: 100%;

      .manage-more {
        a {
          svg {
            font-size: 15px;
          }
        }
      }

      .manage-cards-sections {
        .manage-cards-section {
          display: flex;
          flex-flow: row;
          padding-bottom: 7.5px;
          padding-top: 7.5px;
          justify-content: space-between;

          &:not(:first-child) {
            border-bottom: 1px solid #eee;
          }

        }

        .manage-header {
          h3, a {
            flex: 1 1 0;
          }

          h3 {
            font-size: 16px;
            color: black;
            margin: 0;
          }

          a {
            color: black;
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            align-items: center;

            p {
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              line-height: 32px;
              padding-right: 8px;
            }

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 30px;

              background-color: #3ba9dc;
              border-radius: 4px;
              line-height: 27px;
              height: 24px;
              width: 24px;
              color: white;
            }
          }
        }
      }

      .cards-table {
        thead {
          display: none;
        }

        tbody tr {
          background-color: transparent;
          font-size: 12px;

          td {
            padding: 12px 7.5px;
            vertical-align: middle;
          }

          &:nth-child(1) {
            td {
              border-top: none;
            }
          }

          &:last-child {
            border-bottom: 1px solid #dee2e6;
          }

          td:nth-child(1) {
            padding-left: 0;
            width: 50px;

            .scheme-logo-item {
              display: block;

              img {
                height: 100%;
                max-height: 32px;
              }
            }
          }

          td:nth-child(2) {
            white-space: nowrap;
            width: 1px;

            .wrapper {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            }
          }

          td:nth-child(4) {
            padding-right: 0;

            .wrapper {
              display: flex;
              flex-flow: row;
              justify-content: flex-end;
              flex-basis: 30%;

              a {
                display: flex;
                align-items: center;
                color: black;

                p {
                  display: none;
                  margin: 0;
                  line-height: 30px;
                  padding-right: 8px;
                  font-size: 14px;
                }

                span {
                  background-color: #bfbcb5;
                  border-radius: 4px;
                  line-height: 27px;
                  height: 24px;
                  width: 24px;
                  color: white;
                  font-size: 30px;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }

        }

        @media (min-width: 380px) {
          tbody tr {
            td:nth-child(4) {
              .wrapper {
                a {
                  p {
                    display: block;
                  }
                }
              }
            }
          }
        }

        @media (min-width: 980px) {
          tbody tr {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
