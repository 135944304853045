<template>
  <div class="view-manage-cards">
    <div class="banner">
      <div class="banner-inner">
        <div class="banner-content">
          <h1>{{ $translations.getTranslation('manage_cards_title1') }}</h1>

          <div class="title2">
            <h2>{{ $user.getName() }}</h2>
          </div>

          <p>{{ $translations.getTranslation('manage_cards_label1') }}</p>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="content-inner">

        <div class="component-wrapper">
          <b-container>
            <b-row>
              <b-col sm="12" md="10" lg="7">
                <cards-manager/>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="component-wrapper" v-if="showManageBankAccountSection">
          <b-container>
            <b-row>
              <b-col sm="12" md="10" lg="7">
                <bank-account-manager :showForm="showForm"/>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="component-wrapper">
          <b-container>
            <b-row>
              <b-col sm="12" md="10" lg="7">
                <transactions-list :postings="transactionsList.postings"
                                   :totalRows="transactionsList.totalRows"
                                   :loading="isLoading()"
                                   @onTransactionsModalOpen="onTransactionsModalOpen"/>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="component-wrapper">
          <b-container>
            <b-row>
              <b-col sm="12" md="10" lg="7">
                <loyal-solutions-logo/>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <footer>

      <div class="footer-inner">
        <b-container  >
          <b-row>
            <b-col lg-5 class="text-center">
              <h2>{{ $translations.getTranslation('footer_use_your_benefits_header') }}</h2>
              <p class="mb-2">{{ $translations.getTranslation('footer_use_your_benefits_text1') }}</p>
              <p class="mb-4">{{ $translations.getTranslation('footer_use_your_benefits_text2') }}</p>
              <a class="link-button" href="https://fdm.dk/kampagne/brug-fordele" target="_blank" rel="noopener"
                 aria-describedby="accessibility-external-link-disclaimer">{{
                  $translations.getTranslation('footer_use_your_benefits_button')
                }}</a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </footer>

    <modal-transactions-list
        :postings="transactionsListModal.postings"
        :totalRows="transactionsListModal.totalRows"
        :perPage="transactionsListModal.perPage"
        :loading="isLoading()"
        @onPaginationPageChange="onPaginationPageChange"/>

    <modal-card-added/>
    <modal-pure-dankort-card-linked/>
  </div>
</template>

<script>
// Components
import CardsManager from '@/views/manageCards/components/CardsManager';
import BankAccountManager from '@/views/manageCards/components/BankAccountManager';
import TransactionsList from '@/views/manageCards/components/TransactionsList';
import LoyalSolutionsLogo from '@/components/ui/lsLogo/Logo';

// Modals
import ModalTransactionsList from '@/views/manageCards/components/ModalTransactionsList';
import ModalCardAdded from '@/views/manageCards/components/ModalCardAdded';
import ModalPureDankortCardLinked from "@/views/manageCards/components/ModalPureDankortCardLinked";

// Models
import TransactionsApi from "@/models/transactionsApi";
import ProfileApi from "@/models/profileApi";

export default {
  name: 'ViewManageCards',

  props: {
    triggerModalCardAdded: {
      default: false,
      type: Boolean
    },
    justLinkedPureDankort: {
      default: false,
      type: Boolean
    }
  },

  components: {
    CardsManager,
    BankAccountManager,
    TransactionsList,
    ModalCardAdded,
    ModalPureDankortCardLinked,
    ModalTransactionsList,
    LoyalSolutionsLogo
  },

  data: function () {
    return {
      transactionsList: {
        postings: [],
        totalRows: 0,
      },

      transactionsListModal: {
        postings: [],
        perPage: 10,
        totalRows: 0,
      },

      loading: false,
      showManageBankAccountSection: false,
      showForm: false,
      
    }
  },

  methods: {
    startLoader() {
      this.loading = true;
    },

    stopLoader() {
      this.loading = false;
    },

    isLoading() {
      return this.loading;
    },

    getTransactions() {
      this.startLoader();

      let formData = {
        params: {
          start: 0,
          length: 15
        },
      };

      let _this = this;
      new TransactionsApi(formData, {}).get(function (response) {
        _this.stopLoader();

        if (!response.status || response.status === 401 || response.status === 403) {
          _this.$root.killAuth();
          return;
        }

        if (response.status !== 200) {
          // No need for global error, the Transactions table will show a message "No transactions to show."
          return;
        }

        let postings = response.data;

        _this.transactionsList.postings = postings.data;
        _this.transactionsList.totalRows = postings.total;
      });
    },

    getTransactionsModal(data) {
      this.startLoader();
      this.resetTransactionsListModalPostings();

      let formData = {
        params: {
          start: (this.transactionsListModal.perPage * data.currentPage) - this.transactionsListModal.perPage,
          length: this.transactionsListModal.perPage
        },
      };

      let _this = this;
      new TransactionsApi(formData, {}).get(function (response) {
        _this.stopLoader();

        if (!response.status || response.status === 401 || response.status === 403) {
          _this.$root.killAuth();
          return;
        }

        if (response.status !== 200) {
          // No need for global error, the Transactions table will show a message "No transactions to show."
          return;
        }

        let postings = response.data;

        _this.transactionsListModal.postings = postings.data;
        _this.transactionsListModal.totalRows = postings.total;
      });
    },

    resetTransactionsListModalPostings() {
      this.transactionsListModal.postings = [];
    },

    onTransactionsModalOpen() {
      let data = {
        currentPage: 1,
      }

      this.getTransactionsModal(data);
    },

    onPaginationPageChange(data) {
      this.getTransactionsModal(data);
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      let _this = this;
      new ProfileApi({}, {}).get(function (response) {
        _this.$user.setActiveBankAccounts(response.data.activeBankAccounts);
        if (_this.justLinkedPureDankort && _this.$user.getActiveBankAccounts() === 0) {
          _this.$root.$emit('bv::show::modal', 'modal-pure-dankort-card-linked');
        } else {
          if (_this.triggerModalCardAdded) {
            _this.$root.$emit('bv::show::modal', 'modal-card-added');
          }
        }
      });


      this.getTransactions();
      this.showManageBankAccountSection = this.$user.canSeeBankAccountsSection();
    });
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'modal-pure-dankort-card-linked') {
        this.showManageBankAccountSection = true;
        this.showForm = true;
      }
    });
  },
}
</script>

<style lang="scss" scoped>
.view-manage-cards {
  width: 100%;

  .content {
    width: 100%;

    .content-inner {
      width: 100%;
      max-width: $appWidth;
      margin: auto;
      padding: 60px 8px;

      .component-wrapper {
        width: 100%;
        margin-bottom: 32px;
      }
    }
  }

  .banner {
    width: 100%;
    height: 140px;
    background-size: cover;
    background-image: url('~@/assets/images/manageCards/banner.jpg');
    background-position: 0 30%;

    .banner-inner {
      width: 100%;
      height: 100%;
      max-width: $appWidth;
      margin: auto;
      display: flex;
      align-items: center;

      .banner-content {
        width: 100%;
        max-width: fit-content;
        min-width: 300px;
        padding: 0 8px;

        h1 {
          font-size: 22px;
          line-height: 22px;
          padding: 5px 55px 5px 10px;
          background-color: #3ba9dc;
          font-family: "mada-heavy", Helvetice Neue, arial, sans-serif;
        }

        .title2 {
          position: relative;
          padding: 5px 55px 5px 10px;
          background-color: #f9bf3b;

          width: calc(100% - 160px);
          margin-bottom: 16px;

          &:after {
            position: absolute;
            top: 0;
            right: -3.5em;
            display: block;
            width: 70px;
            height: 100%;
            content: "";
            background-image: linear-gradient(270deg, rgba(249, 191, 59, .25) 33.3%, rgba(249, 191, 59, .5) 0, rgba(249, 191, 59, .5) 66.6%, rgba(249, 191, 59, .75) 0, rgba(249, 191, 59, .75) 99.9%);
          }
        }

        h2 {
          font-size: 14px;
          line-height: 12px;
          font-family: "mada-heavy", Helvetice Neue, arial, sans-serif;

          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 0;
        }

        p {
          display: none;
        }
      }
    }
  }

  @media (min-width: $lgBreakpoint) {
    .banner {
      height: 400px;
      background-position: 40% 75%;

      .banner-inner {
        .banner-content {
          max-width: 500px;

          h1 {
            height: 56px;
            font-size: 52px;
            line-height: 52px;
          }

          .title2 {
            &:after {
              width: 160px;
              right: -8em;
            }
          }

          h2 {
            height: 30px;
            font-size: 32px;
            line-height: 32px;
          }

          p {
            display: block;
          }

        }
      }
    }

    .content {
      .content-inner {
        .component-wrapper {
          margin-bottom: 64px;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .banner {
      height: 400px;

      .banner-inner {
        .banner-content {
          padding: 0 0;
        }
      }
    }

    .content {
      .content-inner {
        padding: 60px 0;
      }
    }
  }
}

footer {
  width: 100%;
  float: left;
  padding: 4rem 0;
  margin-bottom: 0;
  background-color: #3ba9dc;

  .footer-inner {
    width: 100%;
    max-width: $appWidth;
    margin: auto;
  }


  // Text Colors
  * {
    color: #f2f2f2;
  }

  h2 {
    color: #fff;
  }
}

.link-button {
  width: auto;
  padding: .6rem 1rem;
  margin: 0 .2em 0 0;
  border: 2px solid;
  border-radius: 2px;
  font-family: merriweather-heavy, TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  border-color: #f9bf3b;
  background-color: #f9bf3b;
  color: #222;
  display: inline-block;

  &:hover {
    border-color: #f7af0a;
    background-color: #f7af0a;
    text-decoration: none;
  }
}
</style>

