<template>
  <div id="link_bank_account" class="view-link-bank-account">
    <div class="right-column-inner">
      <form @submit.prevent>
        <div class="row-spacing">
          <b-row>
            <b-col sm="3">
              <input-floating-label
                  id="reg-no"
                  :mask="'####'"
                  :errorMessage="getRegNoErrorMsg()"
                  :labelName="$translations.getTranslation('link_bank_account_reg_number_label')"
                  @onInput="onInputRegNo"
                  :max-length="'4'"

              >
              </input-floating-label>
            </b-col>

            <b-col sm="9">
              <input-floating-label
                  id="bank-account-number"
                  :mask="'##########'"
                  :max-length="'10'"
                  :errorMessage="getBankAccountErrorMsg()"
                  :labelName="$translations.getTranslation('link_bank_account_number_label')"
                  @onInput="onInputBankAccountNumber"
              >

              </input-floating-label>
            </b-col>
          </b-row>

        </div>

        <div class="row-spacing">
          <button-primary :disabled="!isFormValid" @click.native="submitForm" :spinner="isFormLoading">
            {{ $translations.getTranslation('link_bank_account_form_button') }}
          </button-primary>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
// Components

// UI
import ButtonPrimary from '@/components/ui/button/Primary';
import InputFloatingLabel from '@/components/ui/input/FloatingLabel';

// Models
import LinkBankAccountApi from '@/models/linkBankAccountApi';

// Modals

// Mixins
import {mixinRoutesHandler} from '@/mixins/routesHandler';

export default {
  name: 'ViewLinkBankAccount',

  props: {
    autoLogin: {
      default: false,
      type: Boolean
    },
    code: {
      default: null,
      type: String
    },
    state: {
      default: null,
      type: String
    },
    nonce: {
      default: null,
      type: String
    },
    showKillAuthError: {
      default: false,
      type: Boolean
    }
  },

  mixins: [mixinRoutesHandler],

  components: {
    InputFloatingLabel, ButtonPrimary
  },

  data: function () {
    return {
      tooltipErrorMessages: {
        regNo: null,
        bankAccountNumber: null,
      },

      userInput: {
        regNo: null,
        bankAccountNumber: null,
      },

      formDataState: {
        isValidRegNo: false,
        isValidBankAccountNumber: false
      },

      isFormValid: false,
      isFormLoading: false,
    }
  },

  methods: {
    getRoutePath(routeName) {
      return this._mixinGetRoutePath(routeName);
    },

    goToRoute(routeName) {
      this._mixinGoToRouteName(routeName);
    },

    startFormLoader() {
      this.isFormLoading = true;
    },

    stopFormLoader() {
      this.isFormLoading = false;
    },

    clearForm() {
      this.$root.$emit('ls::input-floating-label::reset-value', 'reg-no');
      this.$root.$emit('ls::input-floating-label::reset-value', 'bank-account-number');
    },

    onInputRegNo(obj) {
      this.userInput.regNo = obj.value ? obj.value.replace(/\s/g, "") : obj.value;
      this.setRegNo(this.userInput.regNo);
      this.setIsRegNoValidState(false);
      this.resetRegNoTooltipErrorMsg();

      if (this.userInput.regNo.length === 4) {
        this.setIsRegNoValidState(true);
        return;
      }

      this.setTooltipErrorMsg('regNo', this.$translations.getTranslation('link_bank_account_reg_no_error_message'));
    },


    onInputBankAccountNumber(obj) {
      this.userInput.bankAccountNumber = obj.value ? obj.value.replace(/\s/g, "") : obj.value;
      this.setBankAccountNumber(obj.value);
      this.setIsBankAccountNumberValidState(false);
      this.resetBankAccountNumberTooltipErrorMsg();

      if (this.userInput.bankAccountNumber.length === 10) {
        this.setIsBankAccountNumberValidState(true);
        return;
      }

      this.setTooltipErrorMsg('bankAccountNumber', this.$translations.getTranslation('link_bank_account_number_error_message'));
    },

    submitForm() {
      this.startFormLoader();
      let _this = this;

      const data = {
        params: {
          regNo: this.userInput.regNo,
          bankAccountNumber: this.userInput.bankAccountNumber,
        },
      };

      new LinkBankAccountApi(data, {}).post(function (response) {
        if (!response.status || response.status === 401 || response.status === 403) {
          _this.clearForm();
          _this.stopFormLoader();
          _this.$root.killAuth();
          return;
        }

        if (response.status !== 200) {
          _this.stopFormLoader();

          _this.$root.$emit('ls::global-alert::error', _this.$translations.getTranslation(
              response.data.errors ? response.data.errors : 'link_bank_account_global_alert_generic_error_message'
          ));
          return;
        }
        _this.stopFormLoader();
        _this.$emit('onFormSubmitted', '');
      });
    },

    formStateValidHandler() {
      this.isFormValid = (this.formDataState.isValidBankAccountNumber && this.formDataState.isValidRegNo);
    },

    getBankAccountErrorMsg() {
      return this.tooltipErrorMessages.bankAccountNumber;
    },

    getRegNoErrorMsg() {
      return this.tooltipErrorMessages.regNo;
    },

    setRegNo(regNo) {
      // Strip white spaces & set user input card number
      this.userInput.regNo = regNo ? regNo.replace(/\s/g, "") : regNo;
    },
    setBankAccountNumber(bankAccountNumber) {
      // Strip white spaces & set user input card number
      this.userInput.bankAccountNumber = bankAccountNumber ? bankAccountNumber.replace(/\s/g, "") : bankAccountNumber;
    },

    setTooltipErrorMsg(target, msg) {
      this.tooltipErrorMessages[target] = msg;
    },

    setIsRegNoValidState(state) {
      this.formDataState.isValidRegNo = state;
    },

    setIsBankAccountNumberValidState(state) {
      this.formDataState.isValidBankAccountNumber = state;
    },

    resetRegNoTooltipErrorMsg() {
      this.tooltipErrorMessages.regNo = null;
    },

    resetBankAccountNumberTooltipErrorMsg() {
      this.tooltipErrorMessages.bankAccountNumber = null;
    },
  },

  watch: {
    'formDataState': {
      handler() {
        this.formStateValidHandler();
      },
      deep: true
    },
    '$root.userClass': {
      handler() {
        this.checkAuthState();
      },
      deep: true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
