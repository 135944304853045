<template>
  <modal-default refs="modal-last-card-removed">
    <template v-slot:content>
      <h3>
        {{ $translations.getTranslation('last_card_removed_modal_title1') }}
      </h3>

      <p>
        {{ $translations.getTranslation('last_card_removed_modal_label1') }}
      </p>
    </template>

    <template v-slot:buttons>
      <button-primary @click.native="addCard">
        {{ $translations.getTranslation('last_card_removed_modal_button1') }}
      </button-primary>
    </template>
  </modal-default>
</template>

<script>
  // Modals
  import ModalDefault from '@/components/ui/modal/components/Default';

  // Buttons
  import ButtonPrimary from '@/components/ui/button/Primary';

  export default {
    name: "ModalLastCardRemoved",

    props: {
      data: {
        default: null,
        type: Object
      }
    },

    components: {
      ModalDefault, ButtonPrimary
    },

    methods: {
      addCard() {
        this.$router.push({
          name: 'LinkCard'
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .button-wrapper {
    width: 100%;
    max-width: 300px;
    margin: auto;

    button {
      margin-bottom: 16px;
    }
  }
</style>
