<template>
  <div class="the-transactions-list-component">
    <div class="postings-wrapper">
      <h3 v-if="!modal">
        {{ $translations.getTranslation('transactions_list_title1') }}
      </h3>

      <h3 v-if="modal">
        {{ $translations.getTranslation('transactions_list_modal_title1') }}
      </h3>

      <p class="bread-info" v-if="!modal">
        {{ $translations.getTranslation('transactions_list_label1') }}
      </p>

      <b-table id="transactions-list-table" striped hover :fields="fields" :items="current" show-empty>
        <template slot="empty">
          <i v-if="isLoading()">{{ $translations.getTranslation('transactions_list_loading_data_label') }}</i>
          <i v-else>{{ $translations.getTranslation('transactions_list_no_transactions_message_label') }}</i>
        </template>

        <template v-slot:cell(status)="data">
          <div class="dot-item-wrapper">
            <div class="dot-item" :class="getStatusDotClass(data.value)" :title="getStatusDotTitle(data.value)"></div>
          </div>
        </template>

        <template v-slot:cell(trans_date)="data">
          {{ formatDate(data.value) }}
        </template>

        <template v-slot:cell(reference)="data">
          <span :title="data.value">
            {{ data.value }}
          </span>
        </template>

        <template v-slot:cell(cashback)="data">
          {{ formatAmount(data.value) }}
        </template>

        <template v-slot:cell(schemeAsImage)="data">
          <img class="d-none d-sm-block" :src="require(`@/assets/images/schemes/${data.item.scheme}.png`)" alt="Icon">
        </template>

        <template v-slot:cell(cardLastFour)="data">
          *** {{data.item.card_pan_last_4}}
        </template>

      </b-table>

      <b-container class="dot-items-wrapper flex-column">
        <b-row no-gutters>
          <b-col lg="2" md="4">
            <div class="dot-item">
              <span class="available-dot" :title="$translations.getTranslation('transactions_list_available_dot_label')"></span>
              <p>
                {{ $translations.getTranslation('transactions_list_available_dot_label') }}
              </p>
            </div>
          </b-col>
          <b-col lg="2" md="4">
            <div class="dot-item">
              <span class="pending-dot" :title="$translations.getTranslation('transactions_list_pending_dot_label')"></span>
              <p>
                {{ $translations.getTranslation('transactions_list_pending_dot_label') }}
              </p>
            </div>
          </b-col>
          <b-col lg="2" md="4">
            <div class="dot-item">
              <span class="failed-dot" :title="$translations.getTranslation('transactions_list_refunded_dot_label')"></span>
              <p>
                {{ $translations.getTranslation('transactions_list_refunded_dot_label') }}
              </p>
            </div>
          </b-col>
          <b-col lg="3" md="4">
            <div class="dot-item">
              <span class="failed_card_payment-dot" :title="$translations.getTranslation('transactions_list_failed_clearhaus_dot_label')"></span>
              <p>
                {{ $translations.getTranslation('transactions_list_failed_clearhaus_dot_label') }}
              </p>
            </div>
          </b-col>
          <b-col lg="3" md="4">
            <div class="dot-item">
              <span class="failed_bank_payment-dot " :title="$translations.getTranslation('transactions_list_failed_bank_dot_label')"></span>
              <p>
                {{ $translations.getTranslation('transactions_list_failed_bank_dot_label') }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div >


      </div>

      <a href="#" target="_self" v-on:click.prevent="viewMoreClick()" v-if="isViewMoreVisible() && !modal" role="button">
        {{ $translations.getTranslation('transactions_list_view_more_button') }} <b-icon icon="chevron-down" aria-hidden="true" role="presentation"></b-icon>
      </a>

      <a href="#" target="_self" v-on:click.prevent="viewAllClick()" v-if="isViewAllVisible()  && !modal" role="button">
        {{ $translations.getTranslation('transactions_list_view_all_button') }} <b-icon icon="chevron-down" aria-hidden="true" role="presentation"></b-icon>
      </a>

      <div class="pagination-wrapper" v-if="totalRows > perPage && modal">
        <b-pagination v-model="pagination.currentPage"
                      :per-page="perPage"
                      :total-rows="totalRows"
                      :limit="5"/>
      </div>
    </div>

  </div>
</template>


<script>
  import FormatsHandler from '@/models/formatsHandler';
  import moment from 'moment';

  export default {
    name: "TheTransactionsList",

    props: {
      perPage: {
        default: 0,
        type: Number
      },
      postings: Array,
      totalRows: Number,
      modal: {
        default: false,
        Type: Boolean
      },
      loading: {
        default: false,
        type: Boolean
      }
    },

    data() {
      return {
        visibleBreakPoint: 15,
        visibleLimit: 10,
        current: [],

        pagination: {
          currentPage: 1,
        },

        fields: [
          {
            key: 'status',
            sortable: false
          },
          {
            key: 'trans_date',
            sortable: false
          },
          {
            key: 'reference',
            sortable: false
          },
          {
            key: 'cashback',
            sortable: false
          },
          {
            key: 'schemeAsImage',
            sortable: false
          },
          {
            key: 'cardLastFour',
            sortable: false
          },

        ],
      }
    },

    methods: {
      isLoading() {
        return this.loading;
      },

      setCurrent() {
        const postings = this.postings;
        const limit    = this.visibleLimit;

        let count   = 0;
        let current = [];
        for (let prop in postings) {
          if (count < limit) {
            current.push(postings[prop]);
            count++;
          }
        }

        this.current = current;
      },

      isViewMoreVisible() {
        const limit      = this.visibleLimit;
        const total      = this.totalRows;
        const breakPoint = this.visibleBreakPoint;

        return limit < total && limit < breakPoint;
      },

      isViewAllVisible() {
        const limit      = this.visibleLimit;
        const total      = this.totalRows;
        const breakPoint = this.visibleBreakPoint;

        return limit < total && total > breakPoint && limit >= breakPoint;
      },

      viewMoreClick() {
        this.visibleLimit = this.visibleBreakPoint;
      },

      viewAllClick() {
        this.$root.$emit('bv::show::modal', 'modal-transactions-list');
        this.$emit('onTransactionsModalOpen', true);
      },

      formatDate(date) {
        return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD, MMM YY');
      },

      formatAmount(amount) {
        return new FormatsHandler().formatCurrency(amount);
      },

      getStatusDotClass(status) {
        return new FormatsHandler().getStatusDotClass(status);
      },

      getStatusDotTitle(status) {
        switch (status) {
          case 'available':
            return this.$translations.getTranslation('transactions_list_available_dot_label') ;
          case 'failed':
            return this.$translations.getTranslation('transactions_list_refunded_dot_label');
          case 'failed_card_payment':
            return this.$translations.getTranslation('transactions_list_failed_clearhaus_dot_label');
          case 'failed_bank_payment':
            return this.$translations.getTranslation('transactions_list_failed_bank_dot_label');
          default:
            return this.$translations.getTranslation('transactions_list_pending_dot_label');
        }
      }
    },

    mounted() {
      this.$nextTick(function () {
        this.setCurrent();
      })
    },

    watch: {
      'postings': function () {
        this.setCurrent();
      },
      'visibleLimit': function () {
        this.setCurrent();
      },
      'pagination.currentPage': function () {
        this.$emit('onPaginationPageChange', {currentPage: this.pagination.currentPage});
      },
    }
  }
</script>

<style scoped lang="scss">
  .the-transactions-list-component {

    ::v-deep .postings-wrapper {
      width: 100%;

      a {
        svg {
          font-size: 15px;
        }
      }

      h3 {
        text-transform: uppercase;
        font-size: 16px;
      }

      .bread-info {
        font-size: 14px;
      }

      #transactions-list-table {
        width: 100%;

        thead {
          display: none;
        }

        tbody tr {
          background-color: transparent;
          font-size: 12px;

          td {
            padding: 12px 7.5px;
          }


          &:nth-child(1) {
            td {
              border-top: none;
            }
          }

          &:last-child {
            border-bottom: 1px solid #dee2e6;
          }

          td:nth-child(1) {
            padding-left: 0;
            width: 24px;
            vertical-align: middle;
          }

          td:nth-child(2) {
            width: 85px;
            white-space: nowrap;
          }

          td:nth-child(3) {
            position: relative;
            width: 100%;

            span {
              position: absolute;
              left: 0;
              right: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          td:nth-child(4) {
            text-align: right;
            white-space: nowrap;
          }
          td:nth-child(5) {
            text-align: right;
            white-space: nowrap;
            padding: 5px 0;
          }
          td:nth-child(6) {
            text-align: right;
            white-space: nowrap;
          }
        }

        .dot-item-wrapper {
          .dot-item {
            &.available-dot {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              width: 0;
              height: 0;
              border-bottom: 10px solid #00bd9c;
            }

            &.pending-dot {
              width: 12px;
              height: 12px;
              border-radius: 0;
              background-color: #ffc804;
            }

            &.failed-dot {
              border-radius: 50%;
              width: 12px;
              height: 12px;
              background-color: #ff665e;
            }

            &.failed_card_payment-dot {
              background-image: url('~@/assets/images/transactionIcons/math-multiplication.png');
              background-repeat: no-repeat;
              background-size: 10px;
              width: 10px;
              height: 10px;
            }

            &.failed_bank_payment-dot {
              background-image: url('~@/assets/images/transactionIcons/math-division.png');
              background-repeat: no-repeat;
              background-size: 12px;
              width: 12px;
              height: 14px;
            }
          }
        }

        @media (min-width: 980px) {
          tbody tr {
            font-size: 16px;
          }
        }
      }

      .dot-items-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-bottom: 15px;

        .dot-item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: 20px;
            color: pink;
          }

          p {
            margin: 0;
            font-size: 12px;
          }

          span {
            margin-right: 5px;
            line-height: 0;

            &.available-dot {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              width: 0;
              height: 0;
              border-bottom: 10px solid #00bd9c;
            }

            &.pending-dot {
              width: 12px;
              height: 12px;
              border-radius: 0;
              background-color: #ffc804;
            }

            &.failed-dot {
              border-radius: 50%;
              width: 12px;
              height: 12px;
              background-color: #ff665e;
            }
            &.failed_card_payment-dot {
              background-image: url('~@/assets/images/transactionIcons/math-multiplication.png');
              background-repeat: no-repeat;
              background-size: 10px;
              width: 10px;
              height: 10px;
            }
            &.failed_bank_payment-dot {
              background-image: url('~@/assets/images/transactionIcons/math-division.png');
              background-repeat: no-repeat;
              background-size: 12px;
              width: 12px;
              height: 14px;
            }
          }
        }
      }

      .pagination-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;

        .pagination {
          justify-content: center;
          margin-bottom: 0;

          .page-item {
            &.active {
              button {
                background-color: #3ba9dc;
                color: white;
                border: 1px solid #0099ff;
              }
            }

            &.disabled {
              display: none;
            }

            .page-link {
              background-color: white;
              color: #3ba9dc;
              border: 1px solid #3ba9dc;
              margin: 1px;

              &:hover {
                background-color: #3ba9dc;
                border: 1px solid #3ba9dc;
                color: white;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    @media (min-width: 320px) {
      .postings-wrapper {
        .bread-info {
          font-size: 16px;
        }

        .dot-items-wrapper {
          flex-direction: row;
        }
      }
    }
  }
</style>